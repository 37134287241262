const BG_Square = () => {
  return (
    <svg
      className="BG-Square"
      width="720"
      height="278"
      viewBox="0 0 720 278"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H720V177.116C720 232.345 675.228 277.116 620 277.116H0V0Z"
        fill="#6272A4"
        fill-opacity="0.5"
      />
    </svg>
  );
};

export default BG_Square;
