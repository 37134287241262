const BG_Square_2 = () => {
  return (
    <svg
      className="BG-Square-2"
      width="720"
      height="278"
      viewBox="0 0 720 278"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 100.884C0 45.6552 44.7715 0.883606 100 0.883606H720V278H0V100.884Z"
        fill="#6272A4"
        fill-opacity="0.5"
      />
    </svg>
  );
};

export default BG_Square_2;
